<template>
  <div class="post page">
    <Nav></Nav>
    <main class="wrap post">
      <div class="u-flex page-head">
        <div class="left">
          <div class="icoitem">
            <div class="ico">寄</div>
          </div>
          <div class="title">寄方信息</div>
          <div class="tips"></div>
        </div>
        <div class="right">
          <router-link to="/user/Addressbook?op=send">
            <a-icon type="container" style="padding-right: 5px" />使用地址簿
          </router-link>
        </div>
      </div>
      <div class="u-flex">
        <div style="width: 50%; padding-right: 100px">
          <div class="addItem">
            <div class="itemlabel u-flex">
              <div class="itemico">*</div>
              <div class="itemname">姓名</div>
            </div>
            <div class="itemtext">{{ send.name }}</div>
          </div>

          <div class="addItem">
            <div class="itemlabel u-flex">
              <div class="itemico"></div>
              <div class="itemname">省市区</div>
            </div>
            <div class="itemtext">{{ send.areaInfo }}</div>
          </div>
        </div>
        <div style="width: 50%; padding-left: 100px">
          <div class="addItem">
            <div class="itemlabel u-flex">
              <div class="itemico"></div>
              <div class="itemname">手机号</div>
            </div>
            <div class="itemtext">{{ send.mobile }}</div>
          </div>
          <div class="addItem">
            <div class="itemlabel u-flex">
              <div class="itemico">*</div>
              <div class="itemname">详细地址</div>
            </div>
            <div class="itemtext">{{ send.address }}</div>
          </div>
        </div>
      </div>
      <div class="u-flex page-head" style="margin-top: 20px">
        <div class="left">
          <div class="icoitem">
            <div class="ico2">收</div>
          </div>
          <div class="title">收方信息</div>
          <div class="tips"></div>
        </div>
        <div class="right">
          <router-link to="/user/Addressbook?op=receive">
            <a-icon type="container" style="padding-right: 5px" />使用地址簿
          </router-link>
        </div>
      </div>
      <div class="u-flex">
        <div style="width: 50%; padding-right: 100px">
          <div class="addItem">
            <div class="itemlabel u-flex">
              <div class="itemico">*</div>
              <div class="itemname">姓名</div>
            </div>
            <div class="itemtext">{{ receive.name }}</div>
          </div>

          <div class="addItem">
            <div class="itemlabel u-flex">
              <div class="itemico"></div>
              <div class="itemname">省市区</div>
            </div>
            <div class="itemtext">{{ receive.areaInfo }}</div>
          </div>
        </div>
        <div style="width: 50%; padding-left: 100px">
          <div class="addItem">
            <div class="itemlabel u-flex">
              <div class="itemico"></div>
              <div class="itemname">手机号</div>
            </div>
            <div class="itemtext">{{ receive.mobile }}</div>
          </div>
          <div class="addItem">
            <div class="itemlabel u-flex">
              <div class="itemico">*</div>
              <div class="itemname">详细地址</div>
            </div>
            <div class="itemtext">{{ receive.address }}</div>
          </div>
        </div>
      </div>
      <div style="padding-top: 40px">
        <div class="postItem u-flex">
          <div class="itemtitle">寄件方式：</div>
          <div class="items">
            <a-radio-group v-decorator="['radio-group']" v-model="mailingTypesId" @change="mailingTypesChange">
              <a-radio v-for="(item, index) in mailingTypes" :key="index" :value="item.id">{{ item.name }}
              </a-radio>
            </a-radio-group>
          </div>
        </div>
        <div class="postItem u-flex" v-if="(mailingTypesId == 2 || mailingTypesId == 3)">
          <div class="itemtitle">收件仓库：</div>
          <div class="items">
            <a-radio-group v-decorator="['radio-group']" v-model="storeId" @change="warehousesChange">
              <a-radio v-for="(item, index) in warehouses" :key="index" :value="item.id">{{ item.name }}
              </a-radio>
            </a-radio-group>
          </div>
        </div>
        <div class="postItem u-flex" v-if="(mailingTypesId == 2 || mailingTypesId == 3) && storeId != 0">
          <div class="itemtitle">仓库地址：</div>
          <div class="items">
            {{ storename }} - {{ storephone }} - {{ storeinfo }}
          </div>
        </div>
        <div class="postItem u-flex" v-if="mailingTypesId == 2">
          <div class="itemtitle">是否自打码：</div>
          <div class="items" style="width:580px">
            <a-switch v-model="ownprint" />
          </div>
        </div>
        <div class="postItem u-flex" v-if="ownprint">
          <div class="itemtitle">货物信息</div>
          <div class="items" style="width:580px">
            <a-input placeholder="请填写货物货物信息" v-model="goodsInfo" style="width: 550px" />
          </div>
        </div>
        <div class="postItem u-flex" v-if="mailingTypesId == 3">
          <div class="itemtitle">填写快递单：</div>
          <div class="items" style="width:580px">{{ postCodes }}</div>
          <div>
            <a-button :size="size" @click="showModal"> 填写快递单 </a-button>
          </div>
        </div>
        <div class="postItem u-flex">
          <div class="itemtitle">货物类型:</div>
          <div class="items">
            <a-radio-group v-decorator="['radio-group']" v-model="goodsType" @change="goodsTypesSelect">
              <a-radio v-for="(item, index) in goodsTypes" :key="index" :value="item.id">{{ item.name }}
              </a-radio>
            </a-radio-group>
            <a-input v-if="showOther" v-model="goodsName" placeholder="请填写货物类型" style="width: 20px">
            </a-input>
          </div>
        </div>
        <div class="postItem u-flex">
          <div class="itemtitle">货物重量:</div>
          <div class="items u-flex" style="width: 800px">
            <!-- <a-slider :v-model="weight" :min="0" :max="1000" :step="1" :tooltip-visible="true" @change="sliderChange" /> -->
            <a-input-number id="inputNumber" size="large" v-model="weight" :min="1" @change="weightChange" />
            <div style="padding-left: 10px;font-size: 24px;font-weight: 700;"> Kg</div>
          </div>
        </div>
        <div class="postItem u-flex">
          <div class="itemtitle">货物件数</div>
          <div class="items" style="width:580px">
            <a-input-number :min="0" :max="1000000" placeholder="请填写货物件数" v-model="goodsCount" style="width: 150px" />
          </div>
        </div>
        <div class="postItem u-flex">
          <div class="itemtitle">运输方式:</div>
          <div class="items">
            <a-radio-group v-decorator="['radio-group']" v-model="shipTypesId" @change="shipTypesChange">
              <a-radio v-for="(item, index) in shipTypes" :key="index" :value="item.id">{{ item.name }}
              </a-radio>
            </a-radio-group>
          </div>
        </div>
        <div class="postItem u-flex">
          <div class="itemtitle">使用包装:</div>
          <div class="items">
            <a-radio-group v-decorator="['radio-group']" v-model="packingTypesId" @change="packingTypesChange">
              <a-radio v-for="(item, index) in packingTypes" :key="index" :value="item.id">{{ item.name }}
              </a-radio>
            </a-radio-group>
          </div>
        </div>
        <div class="postItem u-flex" v-if="packingTypesId > 1">
          <div class="itemtitle">货物体积:</div>
          <div class="items u-flex" style="width: 800px">
            <div style="font-size: 18px;padding-right: 2px;">长：</div>
            <a-input-number id="inputNumber" size="large" v-model="volumel" :min="0" @change="volumeChange" />
            <div style="padding-left: 10px;font-size: 12px;padding-right: 2px;"> 厘米（CM）</div>
            <div style="padding-left: 10px;font-size: 18px;padding-right: 2px;">宽：</div>
            <a-input-number id="inputNumber" size="large" v-model="volumew" :min="0" @change="volumeChange" />
            <div style="padding-left: 10px;font-size: 12px;padding-right: 2px;"> 厘米（CM） </div>
            <div style="padding-left: 10px;font-size: 18px;padding-right: 2px;">高：</div>
            <a-input-number id="inputNumber" size="large" v-model="volumeh" :min="0" @change="volumeChange" />
            <div style="padding-left: 10px;font-size: 12px;"> 厘米（CM） </div>
          </div>
        </div>
        <div class="postItem u-flex">
          <div class="itemtitle">收件方式:</div>
          <div class="items">
            <a-radio-group v-decorator="['radio-group']" v-model="receivingTypesId" @change="receivingTypesChange">
              <a-radio v-for="(item, index) in receivingTypes" :key="index" :value="item.id">{{ item.name }}
              </a-radio>
            </a-radio-group>
          </div>
        </div>
        <div class="postItem u-flex" v-if="receivingTypesId == 2">
          <div class="itemtitle">自提仓库：</div>
          <div class="items">
            <a-radio-group v-decorator="['radio-group']" v-model="receiverstoreId" @change="receivingwarehousesChange">
              <a-radio v-for="(item, index) in warehouses" :key="index" :value="item.id">{{ item.name }}
              </a-radio>
            </a-radio-group>
          </div>
        </div>
        <div class="postItem u-flex">
          <div class="itemtitle">支付方式:</div>
          <div class="items">
            <a-radio-group v-decorator="['radio-group']" v-model="payTypesId" @change="payTypesIdChange">
              <a-radio v-for="(item, index) in payTypes" :key="index" :value="item.id">{{ item.name }}
              </a-radio>
            </a-radio-group>
          </div>
        </div>
        <div class="u-flex" style="width: 100%">
          <div class="postItem u-flex" style="width: 400px">
            <div class="itemtitle" style="width: 180px">是否保价：</div>
            <div class="items" style="width: 180px">
              <a-radio-group v-decorator="['radio-group']" v-model="goodsVal" @change="insured">
                <a-radio :value="1">是</a-radio>
                <a-radio :value="0">否</a-radio>
              </a-radio-group>
            </div>
          </div>
          <div class="postItem u-flex" style="width: 400px">
            <div class="itemtitle">声明价值：</div>
            <div class="items">
              <a-input-number :min="0" :max="1000000" prefix="￥" v-model="customValue" suffix="RMB" style="width: 150px"
                @change="customValueChange" :disabled="goodsVal == 0" />
            </div>
          </div>
          <div class="postItem u-flex" style="padding-left: 10px;width: 400px">
            <div class="itemtitle">费用：</div>
            <div class="items">
              <a-input prefix="￥" v-model="insuredValue" disabled style="width: 150px" />
            </div>
          </div>
          <div class="postItem u-flex" style="padding-left: 10px;width: 200px">
            <a-button :size="size"> 查看收费标准 </a-button>
          </div>
        </div>
        <div class="priceItem">
          <div style="text-align: right" v-on:click="showCop">
            <a-icon type="container" style="padding-right: 5px; color: red" />使用优惠券
          </div>
          <div class="itemDetail">
            <div class="itemDetail-i u-flex">
              <div class="itemDetail-i-title">基础费用：</div>
              <div>{{ freight }}￥</div>
            </div>
            <div class="itemDetail-i u-flex">
              <div class="itemDetail-i-title">包装费用：</div>
              <div>{{ packingPrice }}￥</div>
            </div>
            <div class="itemDetail-i u-flex">
              <div class="itemDetail-i-title">保价费用：</div>
              <div>{{ premium }}￥</div>
            </div>
            <div class="itemDetail-i u-flex">
              <div class="itemDetail-i-title">优惠金额：</div>
              <div>{{ couponMoney }}￥</div>
            </div>
          </div>
        </div>
        <div class="postItem u-flex" style="padding-top: 50px; padding-right: 30px">
          <div class="items" style="text-align: right">
            <div class="itemDetail-i u-flex" style="float: right">
              <div class="itemDetail-i-title">预估费用：</div>
              <div>{{ total }}￥起</div>
            </div>
          </div>
        </div>
        <div class="postItem u-flex" style="padding-right: 30px">
          <a-checkbox style="width:100%" v-model="agreement"> 阅读并同意《电子运单契约条款》 </a-checkbox>
          <div class="items" style="text-align: right">
            <a-button type="primary" :size="size" style="width: 300px" @click="createOrder">
              下单
            </a-button>
          </div>
        </div>
      </div>
    </main>
    <foot></foot>
    <a-modal v-model="visiblePostCode" title="绑定快递单" :footer="null">
      <a-input v-model="postCode" addon-before="快递单号" placeholder="请填写所寄物品快递单号">
        <a-icon slot="addonAfter" type="plus-circle" @click="addPostCode" />
      </a-input>
      <a-layout>
        <a-layout-header>
          <a-col :span="6">
            <a-icon type="check" /> 已绑定快递单
          </a-col>
        </a-layout-header>
        <a-layout-content>
          <a-list item-layout="horizontal" :data-source="postList">
            <a-list-item slot="renderItem" slot-scope="item">
              <a-list-item-meta>
                <a slot="title">{{ item.postCode }}</a>
              </a-list-item-meta>
              <a slot="actions" @click="del(item.postCode)">删除</a>
            </a-list-item>
          </a-list>
        </a-layout-content>
      </a-layout>
    </a-modal>
    <a-modal v-model="visible" title="选择优惠券" @ok="hideCop" :footer="null" :width="800">
      <div class="cop u-flex">
        <div class="copItem" v-for="(coupon, index) in coupon" :key="index">
          <div class="l">
            <div class="money">{{ coupon.amount }}</div>
            <div class="title">{{ coupon.name }} -- {{ coupon.typeName }}</div>
            <div class="time">有效时间：{{ coupon.endDate }}</div>
          </div>
          <div class="r">
            <a-button type="danger" @click="selectcoupon(coupon)">
              立即使用
            </a-button>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import foot from "@/components/foot.vue";
export default {
  data() {
    return {
      total: "--",
      showdagreement: false,
      showdetail: false,
      timepopup: false,
      showgoods: false,
      goodsVal: 0,
      couponVal: false,
      insuredValue: "未保价物品最高赔5倍运费",
      couponValue: "未使用优惠券",
      mailingTypesId: 0,
      shipTypesId: 0,
      packingTypesId: 0,
      receivingTypesId: 0,
      premiumrate: 0,
      premium: 0,
      packingPrice: 0,
      freight: 0,
      customValue: "0",
      send: {
        name: "",
        mobile: "",
        address: "",
        areaInfo: "",
      },
      receive: {
        name: "",
        mobile: "",
        address: "",
        areaInfo: "",
      },
      usercoupon: {},
      couponMoney: 0,
      agreement: false,
      show: false,
      showreceiving: false,
      store: "",
      storeId: 0,
      receiverstore: "",
      receiverstoreId: 0,
      mailingTypes: [],
      goodsTypes: [],
      shipTypes: [],
      packingTypes: [],
      receivingTypes: [],
      warehouses: [],
      storeAddress: "",
      storename: "",
      storephone: "",
      storeinfo: "",
      goodsType: "",
      weight: "0",
      coupon: [],
      size: "large",
      visible: false,
      visiblePostCode: false,
      postCode: '',
      postCodes: '',
      postList: [],
      showOther: false,
      goodsName: '',
      ownprint: false,
      goodsCount: 1,
      goodsInfo: '',
      payTypesId: 1,
      payTypes: [{
        id: 1,
        name: '寄付'
      }, {
        id: 2,
        name: '到付'
      }],
      volumel: 0,
      volumew: 0,
      volumeh: 0,
    };
  },
  components: {
    foot,
    Nav,
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "fetchDate",
  },
  created() {
    this.loadData();
    this.fetchDate();
  },
  methods: {
    fetchDate() {
      let order = this.storage.getItem("Waybill");
      if (order) {
        let send = order.send;
        let receive = order.receive;
        if (send) {
          this.send = send;
        }
        if (receive) {
          this.receive = receive;
        }
      } else {
        this.order = {};
        this.storage.setItem("Waybill", order);
      }
    },
    //绑定数据
    loadData() {
      this.api
        .getwaybilldictionaries()
        .then((res) => {
          this.mailingTypes = res.mailingTypes;
          res.goodsTypes.forEach((item) => {
            item.checked = false;
          });
          this.goodsTypes = res.goodsTypes;
          this.shipTypes = res.shipTypes;
          this.packingTypes = res.packingTypes;
          this.receivingTypes = res.receivingTypes;
          this.warehouses = res.warehouses;

          //默认赋值
          this.mailingTypesId = this.mailingTypes[0].id;
          this.shipTypesId = this.shipTypes[0].id;
          this.packingTypesId = this.packingTypes[0].id;
          this.receivingTypesId = this.receivingTypes[0].id;

          this.showTotial();
        })
        .catch((err) => {
          console.log(err);
        });
      this.api
        .getMyCoupons({})
        .then((res) => {
          this.coupon = res.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //寄件方式
    mailingTypesChange() {
      console.log(this.mailingTypesId);
    },
    //寄件件仓
    warehousesChange() {
      this.warehouses.forEach((item) => {
        if (item.id == this.storeId) {
          this.store = item.name;
          this.storeAddress = item.info;
          var ins = item.info.split(" ");
          this.storename = ins[0];
          this.storephone = ins[1];
          this.storeinfo = ins[2];
        }
      });
    },
    //收件仓库
    receivingwarehousesChange() {
      this.warehouses.forEach((item) => {
        if (item.id == this.receiverstoreId) {
          this.receiverstore = item.name;
        }
      });
    },
    //货物类型
    goodsTypesSelect() {
      this.goodsTypes.forEach((item) => {
        if (item.id == this.goodsType) {
          this._data.type = item.name;
          this._data.id = item.id;
          this.showTotial();
          if (item.name == "其它") {
            this.showOther = true;
          } else {
            this.showOther = false;
          }
        }
      });
    },
    //货物重量
    sliderChange(value) {
      this.weight = value;
      this.showTotial();
    },
    //货物重量
    weightChange(value) {
      this.weight = value;
      this.showTotial();
    },
    //运输方式
    shipTypesChange() {
      //this.shipTypesId = id;
      this.showTotial();
    },
    //包装方式
    packingTypesChange() {
      //this.packingTypesId = id;
      this.showTotial();
    },
    //体积改变
    volumeChange() {
      this.showTotial();
    },
    //收件方式
    receivingTypesChange() {
      //this.receivingTypesId = id;
    },
    //支付方式
    payTypesIdChange() {
      //this.payTypesId = id;
    },
    //保价设置
    insured() {
      if (this.goodsVal == 1) {
        this.insuredValue = this.customValue * this.premiumrate;
      } else {
        this.customValue = 0;
        this.insuredValue = "未保价物品最高赔5倍运费";
      }
      this.showTotial();
    },
    //报价金额设置
    customValueChange() {
      this.insuredValue = this.customValue * this.premiumrate;
      this.showTotial();
    },
    //显示价格信息
    showTotial() {
      this.api
        .getwaybilltotial({
          shipTypesId: this.shipTypesId,
          packingTypesId: this.packingTypesId,
          customValue: this.customValue,
          countryId: 1,
          weight: this.weight,
          couponId: this.usercoupon.id ? this.usercoupon.id : 0,
          volumel: this.volumel,
          volumew: this.volumew,
          volumeh: this.volumeh,
        })
        .then((res) => {
          this.total = res.total;
          this.freight = res.freight;
          this.packingPrice = res.packingPrice;
          this.premium = res.premium;
          this.premiumrate = res.premiumrate;
          this.couponMoney = res.couponAmount;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //选择优惠券
    selectcoupon(val) {
      this.usercoupon = val;
      this.couponValue = val.name;
      this.visible = false;
      this.showTotial();
    },
    //显示优惠券
    showCop() {
      this.visible = true;
    },
    //显示快递单绑定
    showModal() {
      this.visiblePostCode = true;
    },
    //关闭优惠券
    hideCop() {
      this.visible = false;
    },
    //添加快递单
    addPostCode() {
      if (this.postCode == "" || this.postCode.length < 8) {
        this.codeFocus = true;
        return this.$message.error("请填写正确的快递单号码", 3);
      }
      var isIn = false;
      this.postList.forEach(item => {
        if (item.postCode == this.postCode) {
          isIn = true;
        }
      });
      if (!isIn) {
        this.postList.push({
          postCode: this.postCode
        });
        this.postCode = "";
        this.$message.info("添加成功", 3);
      } else {
        this.postCode = "";
        this.$message.info("请不要重复添加", 3);
      }
      var lists = [];
      this.postList.forEach(item => {
        lists.push(item.postCode);
      });
      this.postCodes = lists.join(',');
    },
    //删除快递单
    del(e) {
      var that = this;
      this.$confirm({
        title: "提示",
        content: "确认删除?",
        onOk() {
          var idx = -1;
          that.postList.forEach((item, index) => { //js遍历数组
            if (item.postCode == e) {
              idx = index;
            }
          });
          if (idx != -1) {
            that.postList.splice(idx, 1);
          }
          var lists = [];
          that.postList.forEach(item => {
            lists.push(item.postCode);
          });
          that.postCodes = lists.join(',');
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "test",
      });
    },
    //下单
    createOrder() {
      //寄件人信息
      if (!this.send || this.send.name == "") {
        return this.$message.error("请填写寄件人信息", 3);
      }
      //收件人信息
      if (!this.receive || this.receive.name == "") {
        return this.$message.error("请填写收件人信息", 3);
      }
      //判断是否自打码
      //送到揽件仓
      if (this.mailingTypesId == 2) {
        //自打码
        if (this.ownprint) {
          if (this.goodsCount == 0) {
            return this.$message.error("请填写货物件数", 3);
          }
          if (this.goodsInfo == '') {
            return this.$message.error("请填写货物信息", 3);
          }
        }
      }
      //商品类型
      if (!this._data.id) {
        return this.$message.error("请选择寄送货物", 3);
      }
      if (this._data.type == "其它" && (!this.goodsName || this.goodsName == "")) {
        return this.$message.error("请填写货物类型", 3);
      }
      //包装必须输入体积
      if (this.packingTypesId > 1 && (this.volumel <= 0 || this.volumew <= 0 || this.volumeh <= 0)) {
        return this.$message.error("请填写货物体积信息", 3);
      }
      //寄件仓库
      if (this.mailingTypesId == 2 && this.storeId <= 0) {
        return this.$message.error("请选择寄件仓库", 3);
      }
      //收件仓库
      if (this.receivingTypeId == 2 && this.receiverstoreId <= 0) {
        return this.$message.error("请选择收件仓库", 3);
      }
      //协议
      if (!this.agreement) {
        return this.$message.error("不同意寄件协议吗？", 3);
      }

      var param = {};
      param.senderId = this.send.id;
      param.receiverId = this.receive.id;
      param.goodsTypeId = this._data.id;
      param.weight = this.weight;
      param.shipTypeId = this.shipTypesId;
      param.mailingTypeId = this.mailingTypesId;
      param.ownprint = this.ownprint ? 1 : 0;
      param.goodsCount = this.goodsCount;
      param.goodsInfo = this.goodsInfo;
      param.receivingTypeId = this.receivingTypesId;
      param.insuredPrice = this.customValue;
      param.packingTypeId = this.packingTypesId;
      param.volumeLong = this.volumel;
      param.volumeWide = this.volumew;
      param.volumeHigh = this.volumeh;
      param.senderWarehouseId = this.storeId;
      param.receiverWarehouseId = this.receiverstoreId;
      param.postCodes = this.postCodes;
      param.goodsName = this.goodsName;
      param.payTypeId = this.payTypesId;
      param.planId = 2;
      if (this.usercoupon.id) {
        param.couponId = this.usercoupon.id;
      } else {
        param.couponId = 0;
      }

      this.api
        .createwaybill(param)
        .then((res) => {
          this.storage.setItem("Waybill", {});
          this.$router.push("/post/success?id=" + res.orderNo);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less">
.post {
  height: auto;
}

.ant-btn-primary {
  border-color: #F8CE09;
  background: #F8CE09;
  color: #fff;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  opacity: .85;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #F8CE09;
}

.ant-input-search-button {
  background: #F8CE09;
  color: #fff;
}

.ant-btn:hover,
.ant-btn:focus {
  background: #F8CE09;
  border-color: #F8CE09;
  color: #fff;
  opacity: .85;
}

.ant-input:focus {
  border-color: #F8CE09;
}

.page {
  background-color: #fff;
}

.page-head {
  width: 100%;
  height: 40px;
  line-height: 40px;

  .left {
    width: 80%;
    display: table-cell;

    .icoitem {
      display: table-cell;

      .ico {
        border-radius: 5px;
        width: 5px;
        height: 7px;
        line-height: 7px;
        background-color: #000;
        color: #fff;
        padding: 10px;
        font-size: 15px;
        font-weight: 1500;
        display: table-cell;
      }

      .ico2 {
        border-radius: 5px;
        width: 5px;
        height: 7px;
        line-height: 7px;
        background-color: red;
        color: #fff;
        padding: 10px;
        font-size: 15px;
        font-weight: 1500;
        display: table-cell;
      }
    }

    .title {
      padding-left: 10px;
      font-size: 20px;
      font-weight: 700;
      display: table-cell;
    }

    .tips {
      padding-left: 10px;
      font-size: 10px;
      display: table-cell;
    }
  }

  .right {
    display: table-cell;
  }
}

.addItem {
  width: 100%;
  padding: 5px;

  .itemlabel {
    height: 20px;
    line-height: 20px;
    text-align: center;

    .itemico {
      color: red;
      padding-right: 5px;
    }

    .itemname {
      font-size: 15px;
      color: silver;
    }
  }

  .itemtext {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid silver;
    font-size: 16px;
  }
}

.postItem {
  width: 100%;
  padding-bottom: 15px;

  .itemtitle {
    width: 180px;
    height: 40px;
    line-height: 40px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: darkgray;
    color: #000;
    text-align: center;
  }

  .items {
    // width: 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
  }
}

.priceItem {
  padding-right: 30px;
  float: right;

  .itemDetail {
    float: right;
    border-bottom: 1px solid #000;

    .itemDetail-i {
      padding-top: 10px;
      padding-bottom: 10px;

      .itemDetail-i-title {
        width: 200px;
      }
    }
  }

  .itemTotial {
    float: right;
  }
}

.cop {
  flex-wrap: wrap;
  justify-content: space-between;

  .copItem {
    width: 370px;
    display: flex;
    justify-content: space-between;
    background: #fff3f3;
    align-items: center;
    position: relative;
    border-radius: 8px;
    margin-bottom: 10px;

    .l {
      flex: 1;
      border-right: 2px dotted #999;
      width: 60%;
      padding: 9px 0;
      padding-left: 9px;

      .money {
        font-size: 36px;
        color: #CF0022;
      }

      .title {
        padding: 5px 0;
      }

      .time {
        color: #909399;
      }
    }

    .r {
      text-align: center;
      width: 30%;
    }
  }
}

.ant-layout {
  height: 100%;
}

.ant-layout-sider,
.ant-layout-content,
.ant-layout-header,
.ant-layout-footer {
  background: #ffffff !important;
  padding: 1px;
}

.ant-layout-sider {
  margin-right: 10px;
  text-align: center;
}

.ant-layout-content {
  margin-top: 1px;
}

.ant-layout-header {
  height: 60px;

  .top {
    .ant-col {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .anticon {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}
</style>